import { useCallback } from 'react';
import { useTrackAmplitudeEvent } from '../use-track-amplitude-event';

type ClickType = 'reward' | 'redeem_button' | 'review_button';

export const useTrackGiftCardClick = () => {
  const trackAmplitudeEvent = useTrackAmplitudeEvent();
  return useCallback(
    (clickType: ClickType) => {
      trackAmplitudeEvent('Gift Cards Redeemed', {
        redemptionStep: `gift_card_${clickType}_click`,
      });
    },
    [trackAmplitudeEvent],
  );
};
